// OS form js

function carInsuranceErrorCount() {
    const errorElementsCars = document.querySelectorAll('.esos-comparator-field-error');
    let countVisible = 0;
    errorElementsCars.forEach((carElement) => {
        if (carElement.offsetHeight > 0) {
            countVisible += 1;
        }
    });

    const errorElementsCarsSecond = document.querySelectorAll('.autoverzekering-widget div');
    errorElementsCarsSecond.forEach((carElement) => {
        if (carElement.offsetHeight > 0 && getComputedStyle(carElement).color === 'rgb(184, 63, 58)') {
            countVisible += 1;
        }
    });

    return countVisible;
}

function checkErrors(submitElement) {
    const errorElements = document.querySelectorAll("div[color='#F46C6C']");

    const carInsuranceCount = carInsuranceErrorCount();

    if (errorElements.length === 0 && carInsuranceCount === 0) {
        submitElement.classList.add('cta-loading');
        const loaderBar = document.createElement('div');
        loaderBar.classList.add('cta-loaderbar-container');
        loaderBar.innerHTML = '<div class="loaderbar"></div>';
        submitElement.appendChild(loaderBar);
    }
}

function checkSubmitButton() {
    const submitElement = document.querySelectorAll("button[type='submit']");
    if (submitElement.length > 0) {
        submitElement[0].addEventListener('click', () => {
            // eslint-disable-next-line no-alert
            setTimeout(() => { checkErrors(submitElement[0]); }, 100);
        });
        return;
    }

    setTimeout(() => {
        checkSubmitButton();
    }, 300);
}

function removeLoader() {
    setTimeout(() => {
        const submitElement = document.querySelectorAll('.cta-loading');
        if (submitElement.length === 1) {
            submitElement[0].classList.remove('cta-loading');
            const loaderElement = document.querySelectorAll('.cta-loaderbar-container');
            loaderElement.remove();
        }
    }, 100);
}

function removeLinks() {
    setTimeout(() => {
        let found = false;

        const divs = document.evaluate('//div[not(descendant::div) and contains(.,\'Autoverzekering.nl\')]', document, null, XPathResult.ANY_TYPE, null);

        // Iterate over xpathresult:
        for (let div = divs.iterateNext(); div; div = divs.iterateNext()) {
            div.innerHTML = div.innerHTML
                .replaceAll('Autoverzekering.nl en', '');
            found = true;
            break;
        }

        // If at least one matching link was found, stop the setTimeout
        if (!found) {
            setTimeout(removeLinks, 100);
        }
    }, 100);
}

function removeParagraph() {
    const interval = setInterval(() => {
        const paragraph = document.querySelector('.css-1jqr1wq .css-b45y8e');
        if (paragraph) {
            paragraph.remove();
            clearInterval(interval);
        }
    }, 100);
}

removeLinks();
removeLoader();
checkSubmitButton();
removeParagraph();
